<template lang="pug">
  div
    div.text-center(v-if="loading")
      v-progress-circular( indeterminate color="primary" )
    castboard(v-else)
</template>

<script>
import Castboard from '@/components/Forecasts/Castboard'
import { mapActions } from 'vuex'

export default {
  components: {
      Castboard
  },
  data() {
    return {
      loading:true,
      idCastpoint: null,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if(to.hash) {
          this.idCastpoint =  to.hash.replace('#','')
        }
      }
    },
    idCastpoint: {
      immediate: true,
      handler() {
        this.fetchData()
      }
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions('forecast', ['fetchCastpointContext','resetCastpoints']),
    async fetchData() {
      if(this.idCastpoint) {
        await this.resetCastpoints()

        this.gLoading(this.$t('forecasts.loading.contextCastpoint'))
        await this.fetchCastpointContext(this.idCastpoint)
        this.gLoadingEnd()

        this.loading=false
      }
    }
  }
}
</script>

<style scoped>

</style>
